<template>
  <div class="member-wrapper">
    <div class="page-block"></div>
    <div class="member-container">
      <el-menu class="member-menu" router :default-active="$route.path">
        <div class="info">
          <!-- <el-avatar :src="userinfo.avatar" :size="60">头像</el-avatar> -->
          <img src="@/assets/avatar.jpg" alt="" />
          <template v-if="userinfo.level === '1'">
            <span>{{ vipList[userinfo.level] }}</span>
            <p>欢迎您,{{ userinfo.UserName }}</p>
            <router-link to="/member/vip" href>升级</router-link>
          </template>
          <template v-else>
            <span class="large">{{ vipList[userinfo.level] }}</span>
            <div class="expireTime">
              到期时间: <span>{{ userinfo.VipExpireTime }}</span>
            </div>
            <p>欢迎您,{{ userinfo.UserName }}</p>
          </template>
        </div>
        <el-menu-item index="/member/index">
          <i class="el-icon-house"></i>
          <span slot="title">管理首页</span>
        </el-menu-item>
        <el-menu-item index="/member/vip">
          <svg-icon icon-class="vip"></svg-icon>
          <span slot="title">VIP购买</span>
        </el-menu-item>
        <el-menu-item index="/member/likes">
          <i class="el-icon-star-off"></i>
          <span slot="title">我的收藏</span>
        </el-menu-item>
        <el-menu-item index="/member/order">
          <i class="el-icon-tickets"></i>
          <span slot="title">我的订单</span>
        </el-menu-item>
        <el-menu-item index="/member/message">
          <i class="el-icon-message"></i>
          <template slot="title">
            <el-badge v-if="messageCount" :value="messageCount" :max="99">
              <span>我的消息</span>
            </el-badge>
            <span v-else>我的消息</span>
          </template>
        </el-menu-item>
        <el-menu-item index="/member/upload">
          <i class="el-icon-upload"></i>
          <span slot="title">上传户型</span>
        </el-menu-item>
        <el-menu-item index="/member/uploadList">
          <i class="el-icon-data-analysis"></i>
          <span slot="title">我的上传</span>
        </el-menu-item>
        <el-menu-item index="/member/deal">
          <i class="el-icon-data-analysis"></i>
          <span slot="title">交易明细</span>
        </el-menu-item>
        <!-- <el-menu-item index="/member/info">
          <i class="el-icon-user"></i>
          <span slot="title">个人信息</span>
        </el-menu-item> -->
      </el-menu>
      <div class="member-content">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import store from "../../store";
export default {
  beforeRouteEnter(to, from, next) {
    if (!store.getters.userinfo) {
      next("/");
    } else {
      next();
    }
  },
  data() {
    return {
      vipList: {
        1: "普通会员",
        2: "VIP会员",
      },
    };
  },
  created() {
    this.$store.dispatch("getMessage");
  },
  watch: {
    $route: {
      handler() {
        if (!store.getters.userinfo) {
          this.$router.push({
            path: "/",
          });
        }
      },
    },
  },
  computed: {
    ...mapGetters(["userinfo", "messageCount"]),
  },
};
</script>

<style lang="scss" scoped>
.member-wrapper {
  width: 1200px;
  margin: 0 auto;
  padding: 20px 0;
  .member-container {
    display: flex;
    .member-menu {
      width: 220px;
      flex-shrink: 0;
      border-radius: 4px;
      border-right: 0;
      .info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px;
        padding-bottom: 10px;
        border-bottom: 1px solid #ededed;
        img {
          width: 60px;
          border-radius: 50%;
        }
        .expireTime {
          font-size: 12px;
          color: #999;
          span {
            font-size: 12px;
            color: #f80000;
          }
        }
        span {
          color: #777;
          font-size: 14px;
          margin: 5px 0;
          &.large {
            color: #f80000;
            font-size: 16px;
          }
        }
        p {
          font-size: 14px;
          color: #555;
          margin: 5px 0;
          word-break: break-all;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        a {
          color: #f80000;
          font-size: 14px;
        }
      }
      ::v-deep .el-menu-item {
        padding-left: 60px !important;
      }
      .svg-icon {
        margin-right: 5px;
        width: 24px;
        text-align: center;
        font-size: 15px;
        vertical-align: middle;
        color: #909399;
      }
      .el-menu-item.is-active {
        .svg-icon {
          color: inherit;
        }
      }
    }
    .member-content {
      padding: 0 10px;
      width: 0;
      flex: 1;
      .member-index {
        min-height: 561px;
      }
    }
  }
  ::v-deep .el-badge {
    line-height: 24px;
  }
}
</style>
